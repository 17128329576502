import React from "react";
import { Blob } from "react-blob";
import styled from "styled-components";

export default function Blobs() {
  return (
    <Wrapper>
      <BlobDesign />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: block;
  position: absolute;
  height: 500px;
  width: 500px;
  top: -20px;
  left: -100px;
  opacity: 1;

  /* @media (max-width: 1400px) {
    opacity: 0;
    display: none;
  } */
`;

const BlobDesign = styled(Blob)`
  height: 100%;
  background-color: none;
  opacity: 1;
  border: 1.5px solid
    linear-gradient(
      209.21deg,
      rgb(87, 15, 141) 13.57%,
      rgb(243, 91, 160) 98.38%
    );
  box-shadow: rgb(243 91 160 / 30%) 0px 20px 40px, rgb(0 0 0 / 5%) 0px 1px 3px;
`;
