import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import {
  Caption2,
  H2,
  MediumText,
  SmallText,
  Caption,
} from "../styles/TextStyles";

export default function Branding() {
  return (
    <Wrapper>
      <CardWrapper>
        <Card1></Card1>

        <Card2></Card2>

        <Card3></Card3>

        <Card4>
          <CardText>
            <CardTextWrapper>
              <CardTitle>SOCIAL MEDIA</CardTitle>
              <CardSubtitle>Management</CardSubtitle>
            </CardTextWrapper>
          </CardText>
        </Card4>
      </CardWrapper>
      <TextWrapper>
        <SmallTitle>BRANDING</SmallTitle>
        <Title>Ești pregătit?</Title>
        <Subtitle>
          Social media este cartea de vizită a companiei tale. De aceea, noi
          suntem aici pentru a o face cât mai atractivă pentru publicul tău
          țintă.
        </Subtitle>
        <Link to="#contact">
          <ButtonWrapper>
            <Icon class="buttonIcon" src="./images/icons/account.svg" />
            <ButtonText>Cere o ofertă</ButtonText>
          </ButtonWrapper>
        </Link>
      </TextWrapper>
    </Wrapper>
  );
}

const ButtonText = styled(Caption)`
  margin: auto auto auto 8px;

  color: rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const ButtonWrapper = styled.div`
  max-width: 180px;
  display: flex;
  background: linear-gradient(
    rgba(24, 32, 79, 0.4) 0%,
    rgba(24, 32, 79, 0.4) 100%
  );
  box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
    rgb(0 0 0 / 20%) 0px 0px 0px 0.5px inset;
  border-radius: 30px;
  border: none;
  padding: 10px 30px 10px 12px;
  cursor: pointer;

  @media (prefers-color-scheme: dark) {
    box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
      rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  }

  :hover {
    transform: translateY(-2px);
    box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
      rgb(0 0 0 / 30%) 0px 0px 0px 0.5px inset,
      rgb(0 0 0 / 30%) 0px 10px 40px inset;

    @media (prefers-color-scheme: dark) {
      box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
        rgb(255 255 255 / 40%) 0px 0px 0px 0.5px inset;
    }
  }
`;

const Icon = styled.img`
  margin: auto auto auto 8px;

  ${ButtonWrapper}:hover & {
    transform: scale(1.1);
  }
`;
const CardTitle = styled(Caption2)`
  color: rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const CardSubtitle = styled(SmallText)`
  color: rgba(255, 255, 255, 1);
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const CardTextWrapper = styled.div`
  display: grid;
  gap: 4px;
`;

const CardText = styled.div`
  width: 230px;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 1234px;
  display: grid;
  grid-template-columns: 640px auto;
  gap: 60px;
  margin: 150px auto 300px auto;
  padding: 0px 30px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    grid-template-columns: auto;
    grid-template-rows: auto 280px;
  }

  @media (max-width: 600px) {
    margin: 0px auto 150px auto;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  max-width: 335px;
  transform-origin: left top;
  @media (max-width: 1024px) {
    order: 2;
    left: 50%;
    margin-left: -335px;
  }

  @media (max-width: 768px) {
    transform: scale(0.9);
    left: 0px;
    margin-left: 0px;
  }

  @media (max-width: 640px) {
    transform: scale(0.8);
  }
  @media (max-width: 550px) {
    transform: scale(0.7);
  }
  @media (max-width: 500px) {
    transform: scale(0.6);
  }
  @media (max-width: 414px) {
    transform: scale(0.5);
  }
  @media (max-width: 350px) {
    transform: scale(0.44);
  }
`;

const Card = styled.div`
  position: absolute;
  transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  cursor: auto;

  width: 335px;
  height: 220px;

  box-shadow: rgb(39 77 153 / 20%) 0px 30px 60px,
    rgb(255 255 255 / 30%) 0px 0px 0px 0.5px inset;
  cursor: pointer;
  border-radius: 30px;
  backdrop-filter: blur(20px);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 20px;

  > * {
    transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
    cursor: auto;
  }
`;

const Card1 = styled(Card)`
  top: 60px;
  left: 310px;
  background: url("./images/animations/card1.svg"),
    linear-gradient(rgb(117, 224, 230) 0%, rgb(57, 19, 184) 100%);
  background-position: top right;
  box-shadow: rgb(39 77 153 / 20%) 0px 30px 60px,
    rgb(0 0 0 / 20%) 0px 0px 0px 0.5px inset;

  :hover {
    transform: translate(-30px, -60px) skewX(-10deg) rotateZ(-10deg) scaleX(0.9) !important;
  }

  ${CardWrapper}:hover & {
    transform: translate(-30px, -30px) skewX(-10deg) rotateZ(-10deg) scaleX(0.9);
  }
`;

const Card2 = styled(Card)`
  top: 40px;
  left: 280px;
  background: url("./images/animations/card2.svg"),
    radial-gradient(
      120% 154.37% at 100% 100%,
      rgba(51, 253, 241, 0.1) 0%,
      rgba(200, 96, 0, 0.1) 100%
    );
  background-position: top right;
  box-shadow: rgb(39 77 153 / 20%) 0px 30px 60px,
    rgb(0 0 0 / 20%) 0px 0px 0px 0.5px inset;

  :hover {
    transform: translate(-90px, -50px) skewX(-10deg) rotateZ(-10deg) scaleX(0.9) !important;
  }

  ${CardWrapper}:hover & {
    transform: translate(-90px, -20px) skewX(-10deg) rotateZ(-10deg) scaleX(0.9);
  }
`;

const Card3 = styled(Card)`
  top: 20px;
  left: 200px;
  background: url("./images/animations/card3.svg"),
    radial-gradient(
      100% 128.38% at 100% 100%,
      rgba(51, 168, 253, 0.2) 0%,
      rgba(76, 0, 200, 0.2) 100%
    );
  background-position: top right;
  box-shadow: rgb(39 77 153 / 20%) 0px 30px 60px,
    rgb(0 0 0 / 20%) 0px 0px 0px 0.5px inset;
  :hover {
    transform: translate(-120px, -40px) skewX(-10deg) rotateZ(-10deg)
      scaleX(0.9) !important;
  }

  ${CardWrapper}:hover & {
    transform: translate(-120px, -10px) skewX(-10deg) rotateZ(-10deg)
      scaleX(0.9);
  }
`;

const Card4 = styled(Card)`
  top: 0px;
  left: 0px;
  background: url("./images/animations/card4.svg"),
    radial-gradient(
      218.51% 281.09% at 100% 100%,
      rgba(253, 63, 51, 0.6) 0%,
      rgba(76, 0, 200, 0.6) 45.83%,
      rgba(76, 0, 200, 0.6) 100%
    );

  :hover {
    transform: translate(-30px, -60px) skewX(-10deg) rotateZ(-10deg) scaleX(0.9) !important;
  }

  ${CardWrapper}:hover & {
    transform: translate(-30px, 0px) skewX(-10deg) rotateZ(-10deg) scaleX(0.9);
  }
`;

const TextWrapper = styled.div`
  position: relative;
  display: grid;
  gap: 20px;
  align-content: start;

  @media (max-width: 1024px) {
    order: 1;
    margin: auto;
    justify-items: center;
    text-align: center;
  }

  > * {
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    transition-property: all;
    transition-duration: 0.8s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 0s;
  }
`;

const SmallTitle = styled(Caption2)`
  color: rgba(0, 0, 0, 0.7);
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Title = styled(H2)`
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;

const Subtitle = styled(MediumText)`
  color: #000;
  @media (prefers-color-scheme: dark) {
    color: #fff;
  }
`;
