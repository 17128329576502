import React from "react";
import styled from "styled-components";
import { Caption, H2, H3, MediumText } from "../styles/TextStyles";

export default function Skills() {
  return (
    <Wrapper>
      <TextWrapper>
        <HeroTitle>OUR MINDSET</HeroTitle>
        <HeroSubtitle>
          Suntem creativi, tineri și cu idei moderne. Suntem siguri că orice
          propunere este realizabilă și ne place să colaborăm cu oameni care își
          cunosc serviciile pe dinafară și știu ceea ce le face diferite pe
          piață.
        </HeroSubtitle>
      </TextWrapper>
      <CardWrapper>
        <Card>
          <BackCard1 />
          <InnerCard>
            <Title>Proactivitate</Title>
            <Subtitle>Stăruință și determinare</Subtitle>
            <Details>
              <FeatureRow>
                <Icon />
                <Feature>Gândim strategii</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Le implementăm</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Ne atingem obiectivul</Feature>
              </FeatureRow>
            </Details>
          </InnerCard>
        </Card>

        <Card>
          <BackCard2 />
          <InnerCard2>
            <Title>Agilitate</Title>
            <Subtitle>Responsabilitate și concentrare</Subtitle>
            <Details>
              <FeatureRow>
                <Icon />
                <Feature>Lucrăm inteligent</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Lucrăm eficient</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Lucrăm sustenabil</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Ne adaptăm rapid</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Focusăm strategii imbatabile</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>La curent cu noile tendințe</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon />
                <Feature>Comunicăm constant cu clienții</Feature>
              </FeatureRow>
            </Details>
          </InnerCard2>
        </Card>

        <Card>
          <BackCard3 />
          <InnerCard3>
            <Title>Focusare</Title>
            <Subtitle>Rezultate garantate</Subtitle>
            <Details>
              <FeatureRow>
                <Icon src="./images/icons/check.svg" />
                <Feature>Viziune ambițioasă</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon src="./images/icons/check.svg" />
                <Feature>Implicare în muncă</Feature>
              </FeatureRow>
              <FeatureRow>
                <Icon src="./images/icons/check.svg" />
                <Feature>Îmbunătățire permanentă</Feature>
              </FeatureRow>
            </Details>
          </InnerCard3>
        </Card>
      </CardWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0px auto;
  padding: 0px 30px;
`;
const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 4px;
  margin-top: 44px;

  @media (max-width: 800px) {
    grid-template-columns: auto;
    gap: 60px;
    margin-top: 64px;
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;

  /* Note: backdrop-filter has minimal browser support */
`;

const BackCard1 = styled.div`
  transform-origin: left top;

  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;

  position: absolute;
  max-width: 360px;
  min-width: 240px;
  width: 100%;
  height: 426px;
  background: linear-gradient(
    180deg,
    rgba(108, 207, 238, 0.5) 0%,
    rgba(76, 127, 228, 0.5) 100%
  );
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 0px 60px 60px 60px;
  transform: matrix(0.99, -0.14, 0.15, 0.99, 0, 0);

  ${Card}:hover & {
    transform: rotateX(-30deg) rotateY(30deg) translateY(-3px);
  }
`;

const BackCard2 = styled.div`
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;

  position: absolute;
  width: 100%;
  max-width: 380px;
  height: 519px;
  background: linear-gradient(rgb(47, 184, 255) 0%, rgb(158, 236, 217) 100%);
  border-radius: 60px 60px 60px 0px;
  transform: matrix(1, 0.14, 0, 0.99, 0, 0);
  transform-origin: left bottom;
  z-index: -1;

  ${Card}:hover & {
    transform: rotateX(30deg) rotateY(30deg) translateY(-3px);
  }
`;
const BackCard3 = styled.div`
  transform-origin: right top;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transition-property: all;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-delay: 0s;
  position: absolute;
  max-width: 360px;
  width: 100%;
  height: 426px;
  background: linear-gradient(
    rgb(115, 184, 249) 11.94%,
    rgb(203, 216, 241) 80.98%
  );
  border-radius: 60px 0px 60px 60px;
  transform: matrix(0.99, 0.14, -0.15, 0.99, 0, 0);
  transform-origin: right top;
  z-index: -1;

  ${Card}:hover & {
    transform: rotateX(30deg) rotateY(30deg) translateY(-3px);
  }
`;

const Title = styled(H3)`
  color: #000000;
  margin-top: 26px;
  margin-bottom: 4px;

  @media (prefers-color-scheme: dark) {
    color: #ffffff;
  }
`;

const Subtitle = styled(MediumText)`
  color: rgba(0, 0, 0, 0.7);

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Details = styled.div`
  display: grid;
  row-gap: 8px;
  width: fit-content;
  margin: 60px auto 0px;
`;

const FeatureRow = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  text-align: left;
`;

const Feature = styled(MediumText)`
  color: rgba(0, 0, 0, 0.7);
  margin: auto 0px auto 16px;

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const InnerCard = styled.div`
  transition: 0.2s ease-in-out;
  max-width: 360px;
  min-width: 240px;
  width: 100%;
  height: 426px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: rgb(24 32 79 / 25%) 0px 40px 80px,
    rgb(255 255 255 / 50%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px);
  padding: 50px 20px;
  border-radius: 0px 60px 60px;
  z-index: 2;
  text-align: center;

  @media (prefers-color-scheme: dark) {
    background: rgba(31, 31, 71, 0.6);
    box-shadow: rgb(0 0 0 / 25%) 0px 40px 80px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;
  }

  ${Card}:hover & {
    box-shadow: rgb(0 0 0 / 25%) 0px 100px 100px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;

    transform: translateY(-3px);
  }
`;

const InnerCard2 = styled.div`
  transition: 0.2s ease-in-out;
  min-width: 240px;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: rgb(24 32 79 / 25%) 0px 40px 80px,
    rgb(255 255 255 / 50%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px);
  padding: 50px 20px;
  max-width: 380px;
  height: 519px;
  border-radius: 60px 60px 60px 0px;
  z-index: 2;
  text-align: center;

  @media (prefers-color-scheme: dark) {
    background: rgba(31, 31, 71, 0.6);
    box-shadow: rgb(0 0 0 / 25%) 0px 40px 80px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;
  }
  ${Card}:hover & {
    box-shadow: rgb(0 0 0 / 25%) 0px 100px 100px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;

    transform: translateY(-3px);
  }
`;

const InnerCard3 = styled.div`
  transition: 0.2s ease-in-out;
  max-width: 360px;
  min-width: 240px;
  width: 100%;
  height: 426px;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: rgb(24 32 79 / 25%) 0px 40px 80px,
    rgb(255 255 255 / 50%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px);
  padding: 50px 20px;
  border-radius: 60px 0px 60px 60px;
  z-index: 2;
  text-align: center;
  @media (prefers-color-scheme: dark) {
    background: rgba(31, 31, 71, 0.6);
    box-shadow: rgb(0 0 0 / 25%) 0px 40px 80px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;
  }
  ${Card}:hover & {
    box-shadow: rgb(0 0 0 / 25%) 0px 100px 100px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;

    transform: translateY(-3px);
  }
`;

const Icon = styled.div`
  background: url("./images/icons/check-dark.svg");
  background-repeat: no-repeat;
  @media (prefers-color-scheme: dark) {
    background: url("./images/icons/check.svg");
  }
`;

const TextWrapper = styled.div`
  max-width: 460px;
  margin: 0 auto 120px auto;
`;

const HeroTitle = styled(H2)`
  color: rgba(0, 0, 0, 1);
  text-align: center;
  margin-bottom: 20px;

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 1);
  }
`;

const HeroSubtitle = styled(Caption)`
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;
