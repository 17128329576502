import React from "react";
import styled from "styled-components";

export default function TopWave() {
  return (
    <Wrapper>
      <WaveWrapper>
        <Wave />
      </WaveWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  top: -80px;
  display: block;
`;

const WaveWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
`;

const Wave = styled.div`
  position: absolute;
  width: 100%;
  z-index: -1;
  top: -100px;
  background: url("./images/waves/topwave-light.png") center top / 3000px
    no-repeat;
  height: 800px;

  @media (prefers-color-scheme: dark) {
    background: url("./images/waves/topwave.png") center top / 3000px no-repeat;
  }
  @media (prefers-color-scheme: light) {
    background-blend-mode: hue;
    background-color: rgba(242, 246, 255, 0.9);
  }
`;
