import React from "react";
import styled from "styled-components";

export default function WhatwedoWave() {
  return (
    <Wrapper>
      <Wave></Wave>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  height: 1500px;
  width: 100%;
  /* overflow: hidden; */
  display: block;
`;

const Wave = styled.div`
  position: absolute;
  background-position: center top;
  background-repeat: no-repeat;
  top: -300px;
  background-image: url("./images/waves/certificate-lines.svg");
  height: 600px;
  background-size: 1440px;
  width: 100%;
  z-index: 0;
`;
