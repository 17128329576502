import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { H2 } from "../styles/TextStyles";

export default function Partners() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <Title>Trusted by</Title>
      <Modal>
        <Slider {...settings}>
          <Slide>
            <Logo src="./images/logos/partners/mamamanu.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/homegarden.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/oneliferally.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/safeway.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/pokka.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/remaxgoldpiatraneamt.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/feonixaesthetics.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/luxurytrade.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/skynation.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/influencergroup.svg" />
          </Slide>
          <Slide>
            <Logo src="./images/logos/partners/bossini.svg" />
          </Slide>
        </Slider>
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 200px auto 200px auto;
  max-width: 1254px;
  align-items: center;
`;

const Slide = styled.div`
  display: flex !important;
  align-items: center;
  height: 110px;
`;

const Logo = styled.img`
  width: 120px;
  margin: auto;
  fill: #323232;
`;

const Title = styled(H2)`
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: 30px;
  @media (prefers-color-scheme: dark) {
    color: rgb(255, 255, 255);
  }
`;

const Modal = styled.div`
  position: relative;

  padding: 20px 20px 40px 20px;
  justify-items: center;
  background: rgba(50, 61, 109, 0.5);
  box-shadow: rgb(255 255 255 / 30%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px);
  border-radius: 20px;

  @media (max-width: 1300px) {
    margin: 20px;
  }
`;
