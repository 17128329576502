import React from "react";
import styled from "styled-components";

export default function BottomWave() {
  return (
    <Wrapper>
      <WaveWrapper>
        <Wave1 src="./images/waves/testimonial-wave1.svg"></Wave1>
        <Wave2 src="./images/waves/testimonial-wave2.svg"></Wave2>
        <Wave3 src="./images/waves/testimonial-wave3.svg"></Wave3>
        <Wave4 src="./images/waves/testimonial-wave4.svg"></Wave4>
        <Wave5 src="./images/waves/testimonial-wave5.svg"></Wave5>
      </WaveWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  display: block;
  left: 0;
  height: 800px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 820px) {
    height: 1100px;
  }
`;

const WaveWrapper = styled.div`
  position: absolute;
  height: 1500px;
  width: 100%;
  opacity: 1;
  animation: 1s ease 0s 1 normal forwards running;
`;

const Wave = styled.img`
  position: absolute;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: -1;
`;

const Wave1 = styled(Wave)`
  top: 50px;
  height: 800px;
`;

const Wave2 = styled(Wave)`
  top: 250px;
  width: 100%;
`;
const Wave3 = styled(Wave)`
  top: 380px;
  width: 100%;
`;

const Wave4 = styled(Wave)`
  top: 200px;
  height: 1000px;
`;
const Wave5 = styled(Wave)`
  top: 450px;
  height: 1000px;
`;
