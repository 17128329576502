import React, { useState } from "react";
import styled from "styled-components";
import BottomWave from "../backgrounds/BottomWave";
import { SmallText, H2, MediumText } from "../styles/TextStyles";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha";
import { Link } from "gatsby";

export default function Contact() {
  const [submitted, setSubmitted] = useState(false);

  const submit = (token) => {};
  return (
    <Wrapper>
      <BottomWave />
      <ModalWrapper id="contact">
        <ModalCover>
          <Illustration src="./images/illustrations/teamwork.svg" />
        </ModalCover>
        <ContactWrapper>
          <Title>Contact</Title>
          <Subtitle>
            Spune-ne obiectivele tale, iar noi implementăm strategia pentru a le
            atinge.
          </Subtitle>
          <ContactForm
            method="post"
            action="https://www.flexyform.com/f/5249bd9e5d30ec9255ab96c75d32c6a52a30c43f"
          >
            <FieldWrapper>
              <label for="name">
                <IconWrapper>
                  <Icon src="./images/icons/account.svg" />
                </IconWrapper>
                <Input type="text" name="name" id="name" placeholder="Nume" />
              </label>
            </FieldWrapper>
            <FieldWrapper>
              <label for="email">
                <IconWrapper>
                  <Icon src="./images/icons/email.svg" />
                </IconWrapper>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              </label>
            </FieldWrapper>
            <TextFieldWrapper>
              <label for="message">
                <IconWrapper>
                  <Icon src="./images/icons/pen.svg" />
                </IconWrapper>
                <Textarea
                  name="message"
                  id="message"
                  rows="5"
                  placeholder="Mesaj"
                />
              </label>
            </TextFieldWrapper>
            <ReCaptcha
              action="homepage"
              siteKey="6LdYCXUjAAAAAHGFXu7mMLe4MdQvx5cZTv0T2QjI"
              onVerify={(token) => submit(token)}
              submitted={submitted}
            />
            <Submit onClick={() => setSubmitted(true)} type="submit">
              <BtnTextWrapper>
                <BtnText>Trimite</BtnText>
              </BtnTextWrapper>
            </Submit>
          </ContactForm>
        </ContactWrapper>
      </ModalWrapper>
      <Logo src="./images/logos/webubble-white.svg" />

      <IconWrapperSocial>
        <Link to="https://www.instagram.com/webubble.ro/" target="_blank">
          <SocialWrapper>
            <Social src="/images/icons/instagram.svg" alt="Instagram" />
          </SocialWrapper>
        </Link>
        {/* <Link>
            <SocialWrapper>
              <Social src="/images/icons/facebook.svg" alt="Facebook" />
            </SocialWrapper>
          </Link> */}
        <Link to="https://www.linkedin.com/company/79881372/" target="_blank">
          <SocialWrapper>
            <Social src="/images/icons/linkedin.svg" alt="Linkedin" />
          </SocialWrapper>
        </Link>
      </IconWrapperSocial>
    </Wrapper>
  );
}

const Logo = styled.img`
  margin-top: 50px;
  height: 50px;
`;

const Wrapper = styled.div`
  margin: 100px auto 0 auto;
  display: grid;
  justify-content: center;
  overflow: hidden;
  justify-items: center;
`;

const ModalWrapper = styled.div`
  position: relative;
  max-width: 960px;
  padding: 20px;
  display: grid;
  grid-template-columns: 360px auto;
  gap: 20px;
  justify-items: center;
  background: rgba(50, 61, 109, 0.5);
  box-shadow: rgb(255 255 255 / 30%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px);
  border-radius: 20px;

  @media (max-width: 1024px) {
    margin: 20px;
  }
  @media (max-width: 820px) {
    grid-template-columns: auto;
  }
`;

const ModalCover = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  place-content: center;
  background-image: url("./images/backgrounds/cover.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  box-shadow: rgb(255 255 255 / 30%) 0px 0px 0px 0.5px inset;
`;

const Illustration = styled.img`
  width: 100%;
`;

const ContactWrapper = styled.div`
  display: grid;
  -webkit-box-pack: start;
  justify-content: start;
  text-align: left;
  gap: 20px;
  max-width: 320px;
  padding-top: 75px;
  /* margin-bottom: 70.5px; */
  position: relative;
`;

const Title = styled(H2)`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 0px;
  line-height: 40px;
  color: rgb(255, 255, 255);
`;

const Subtitle = styled(SmallText)`
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
`;

const ContactForm = styled.form`
  display: grid;
  gap: 20px;
  margin: 0px;
`;

const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 44px;
`;

const TextFieldWrapper = styled(FieldWrapper)`
  height: auto;
`;

const IconWrapper = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 36px;
  height: 36px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 1;
`;

const Icon = styled.img`
  margin: auto;
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    rgba(99, 106, 150, 0.4) 0%,
    rgba(182, 186, 214, 0.25) 100%
  );
  border: none;
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
    rgb(255 255 255 / 30%) 0px 0px 0px 0.5px inset;
  box-sizing: border-box;
  padding: 10px 42px 10px 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  :focus {
    outline: none;
    padding-left: 50px;
    box-shadow: rgb(47 184 255 / 30%) 0px 10px 40px,
      rgb(47 184 255) 0px 0px 0px 1px inset;
    background: linear-gradient(
      rgba(24, 32, 79, 0.4) 0%,
      rgba(24, 32, 79, 0.25) 100%
    );
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  background: linear-gradient(
    rgba(99, 106, 150, 0.4) 0%,
    rgba(182, 186, 214, 0.25) 100%
  );
  border: none;
  border-radius: 22px;
  box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
    rgb(255 255 255 / 30%) 0px 0px 0px 0.5px inset;
  box-sizing: border-box;
  padding: 10px 42px 10px 56px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0;
  resize: none;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  :focus {
    outline: none;
    padding-left: 50px;
    box-shadow: rgb(47 184 255 / 30%) 0px 10px 40px,
      rgb(47 184 255) 0px 0px 0px 1px inset;
    background: linear-gradient(
      rgba(24, 32, 79, 0.4) 0%,
      rgba(24, 32, 79, 0.25) 100%
    );
  }
`;

const Submit = styled.button`
  background: linear-gradient(
    91.4deg,
    rgb(47, 184, 255) 0%,
    rgb(158, 236, 217) 100%
  );
  border: none;
  border-radius: 30px;
  box-shadow: rgb(147 231 221 / 30%) 0px 20px 40px;
  cursor: pointer;
  display: grid;
  text-align: center;
  padding: 12px 0px;
  width: 100%;
  position: relative;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

  :hover {
    transform: translateY(-2px);
    box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
      rgb(0 0 0 / 30%) 0px 0px 0px 0.5px inset,
      rgb(0 0 0 / 10%) 0px 10px 40px inset;
  }
`;

const BtnTextWrapper = styled.div`
  width: fit-content;
  margin: 0px auto;
`;

const BtnText = styled(MediumText)`
  font-style: normal;
  font-size: 17px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  margin: 0px;

  @media (prefers-color-scheme: dark) {
    color: rgb(255, 255, 255);
  }
`;

const SocialWrapper = styled.div`
  background: linear-gradient(
    360deg,
    rgba(99, 106, 150, 0.4) 0%,
    rgba(182, 186, 214, 0.5) 100%
  );
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 50%;
  margin: 10px;
  height: 42px;
  width: 42px;

  transition: 0.2s ease-in-out;
  :hover {
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.55);
  }
`;

const Social = styled.img`
  height: 20px;
  opacity: 0.5;
  margin: 10px;

  transition: 0.2s ease-in-out;
  ${SocialWrapper}:hover & {
    opacity: 1;
  }
`;

const IconWrapperSocial = styled.div`
  display: flex;
  padding: 20px 0 20px 0;
`;
