import * as React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import Branding from "../components/sections/Branding";
import HeroSection from "../components/sections/HeroSection";
import Services from "../components/sections/Services";
import Skills from "../components/sections/Skills";
import Contact from "../components/sections/Contact";
import Partners from "../components/sections/Partners";
import TopWave from "../components/backgrounds/TopWave";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Acasă" />
      <CookyWrapper>
        <Cookies
          location="bottom"
          buttonText="Accept"
          containerClasses="cooky"
          buttonClasses="cookyBtn"
          contentClasses="contentCooky"
          buttonWrapperClasses="btnWrapper"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          disableStyles="true"
        >
          WeBubble folosește cookies. Vizitează{" "}
          <Link to="/privacy-policy">politica de confidențialitate</Link> pentru
          detalii.
        </Cookies>
      </CookyWrapper>
      <HeroSection />

      <Branding />
      <TopWave />
      <Skills />
      <Services />
      <Partners />
      <Contact />
    </Layout>
  );
};

const CookyWrapper = styled.div`
  .cooky {
    box-shadow: rgb(0 0 0 / 25%) 0px 40px 80px,
      rgb(255 255 255 / 15%) 0px 0px 0px 0.5px inset;
    backdrop-filter: blur(20px) !important;

    align-items: baseline;
    background: rgba(31, 31, 71, 0.6);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;
  }

  .contentCooky {
    flex: 1 0 300px;
    margin: 15px;
    a {
      color: rgb(255, 255, 255);
      text-decoration: underline;
    }
  }

  .cookyBtn {
    cursor: pointer;
    flex: 0 0 auto;
    margin: 15px;

    background: linear-gradient(
      91.4deg,
      rgb(47, 184, 255) 0%,
      rgb(158, 236, 217) 100%
    );
    border: none;
    border-radius: 30px;
    box-shadow: rgb(147 231 221 / 30%) 0px 20px 40px;
    cursor: pointer;
    text-align: center;
    padding: 12px 0px;
    width: 100%;
    font-family: inherit;
    font-weight: 600;
    position: relative;
    color: white;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    :hover {
      transform: translateY(-2px);
      box-shadow: rgb(0 0 0 / 15%) 0px 20px 40px,
        rgb(0 0 0 / 30%) 0px 0px 0px 0.5px inset,
        rgb(0 0 0 / 10%) 0px 10px 40px inset;
    }
  }

  .btnWrapper {
    margin-right: 30px;
  }
`;

const Cookies = styled(CookieConsent)``;

export default IndexPage;
