import React from "react";
import styled from "styled-components";
import WhatwedoWave from "../backgrounds/WhatwedoWave";
import {
  BodyIntro,
  Caption,
  Caption2,
  H2,
  MediumText,
} from "../styles/TextStyles";

export default function Services() {
  return (
    <Wrapper id="services">
      <WhatwedoWave />
      <HeroWrapper>
        <TextWrapper>
          <Heading>STRATEGIE DIGITALĂ</Heading>
          <Title>What we do</Title>
          <Subtitle>
            Îmbinam în mod profesional instrumentele media cu canalele de
            comunicare și punem în aplicare strategii și campanii care cresc
            vizibilitatea brandului tău în mediul online.
          </Subtitle>
        </TextWrapper>
        <IconRow>
          <LogoWrapper>
            <Icon src="./images/logos/logo1.svg" />
            <Icon src="./images/logos/logo2.svg" />
            <Icon src="./images/logos/logo3.svg" />
            <Icon src="./images/logos/logo4.svg" />
            <Icon src="./images/logos/logo5.svg" />
            <Icon src="./images/logos/logo6.svg" />
            <Icon src="./images/logos/logo7.svg" />
          </LogoWrapper>
          <MobileWrapper>
            <LogoWrapperMob1>
              <Icon src="./images/logos/logo1.svg" />
              <Icon src="./images/logos/logo2.svg" />
              <Icon src="./images/logos/logo3.svg" />
              <Icon src="./images/logos/logo4.svg" />
            </LogoWrapperMob1>

            <LogoWrapperMob2>
              <Icon src="./images/logos/logo5.svg" />
              <Icon src="./images/logos/logo6.svg" />
              <Icon src="./images/logos/logo7.svg" />
            </LogoWrapperMob2>
          </MobileWrapper>
        </IconRow>
      </HeroWrapper>

      <Whatwedo>
        <CardWrapper>
          <div>
            <Card1>
              <IllustrationWrapper>
                <Illustration src="./images/illustrations/illustration1.svg" />
              </IllustrationWrapper>
              <ContentWrapper>
                <CardTitle>Strategie și statistici</CardTitle>
                <CardSubtitle>
                  Pachet complet de servicii profesionale
                </CardSubtitle>
                <Website>webubble.ro</Website>
                <HiddenDetails>
                  <HiddenTitle>Analiză de brand</HiddenTitle>
                  <HiddenText>
                    Înțelegem ideea afacerii tale și istoricul de promovare al
                    acesteia și propunem o strategie nouă, adaptată nevoilor
                    tale.
                  </HiddenText>

                  <HiddenTitle>Stabilirea obiectivelor</HiddenTitle>
                  <HiddenText>
                    Propunem tipuri de campanii și materiale personalizate în
                    conformitate cu publicul țintă al brandului.
                  </HiddenText>

                  <HiddenTitle>Analiză și statistici</HiddenTitle>
                  <HiddenText>
                    Rezultatele strategiei sunt analizate și optimizate frecvent
                    cu tehnici care să sporească creșterea brandului în online
                  </HiddenText>
                </HiddenDetails>
              </ContentWrapper>
            </Card1>
          </div>

          <div>
            <Card2>
              <IllustrationWrapper>
                <Illustration src="./images/illustrations/illustration2.svg" />
              </IllustrationWrapper>
              <ContentWrapper>
                <CardTitle>Media și influenceri</CardTitle>
                <CardSubtitle>
                  Materiale media moderne și campanii cu influenceri
                </CardSubtitle>
                <Website>webubble.ro</Website>
                <HiddenDetails>
                  <HiddenTitle>Materiale media și PR</HiddenTitle>
                  <HiddenText>
                    Realizăm postări și story-uri pentru vizibilitatea brandului
                    în mediul online și menținem legătura cu publicul.
                  </HiddenText>

                  <HiddenTitle>Parteneriate cu persoane publice</HiddenTitle>
                  <HiddenText>
                    Identificarea eficientă a influencerilor pentru colaborări
                    și promovări optime a produselor.
                  </HiddenText>

                  <HiddenTitle>Servicii de copywriting</HiddenTitle>
                  <HiddenText>
                    Conceperea de texte de impact, într-o manieră coerentă și
                    convingătoare pentru publicul țintă.
                  </HiddenText>
                </HiddenDetails>
              </ContentWrapper>
            </Card2>
          </div>

          <div>
            <Card3>
              <IllustrationWrapper>
                <Illustration src="./images/illustrations/illustration3.svg" />
              </IllustrationWrapper>
              <ContentWrapper>
                <CardTitle>
                  Grafică și
                  <br />
                  design
                </CardTitle>
                <CardSubtitle>
                  Îmbinăm arta cu
                  <br />
                  tehnologia
                </CardSubtitle>
                <Website>webubble.ro</Website>
                <HiddenDetails>
                  <HiddenTitle>Design de identitate</HiddenTitle>
                  <HiddenText>
                    Concepem atitudinea vizuală a brandului prin logo-uri,
                    sloganuri, culori și fonturi personalizate.
                  </HiddenText>

                  <HiddenTitle>
                    Design pentru materiale de promovare
                  </HiddenTitle>
                  <HiddenText>
                    Proiectăm identitatea vizuală a materialelor cu scop
                    publicitar.
                  </HiddenText>

                  <HiddenTitle>Design grafic indoor/outdoor</HiddenTitle>
                  <HiddenText>
                    Concepem designul pentru bannere, autocolante, reclame
                    luminoase, panouri stradale,și mesh-uri publicitare.
                  </HiddenText>
                </HiddenDetails>
              </ContentWrapper>
            </Card3>
          </div>

          <div>
            <Card4>
              <IllustrationWrapper>
                <Illustration src="./images/illustrations/illustration4.svg" />
              </IllustrationWrapper>
              <ContentWrapper>
                <CardTitle>Servicii de web design</CardTitle>
                <CardSubtitle>
                  Website-uri de prezentare și magazine online
                </CardSubtitle>
                <Website>webubble.ro</Website>
                <HiddenDetails>
                  <HiddenTitle>Website-uri și e-commerce</HiddenTitle>
                  <HiddenText>
                    Concepem site-uri moderne și atractive pentru brand-ul tău
                    cu viteze eficiente de încărcare și optimizare SEO.
                  </HiddenText>

                  <HiddenTitle>Mentenanță web</HiddenTitle>
                  <HiddenText>
                    Administrăm site-ul afacerii și reîmprospătăm frecvent
                    conținutul de pe acesta.
                  </HiddenText>

                  <HiddenTitle>Găzduire</HiddenTitle>
                  <HiddenText>
                    Îți oferim soluții complete de găzduire web și îți garantăm
                    securitatea datelor și viteza de încărcare a conținutului.
                  </HiddenText>
                </HiddenDetails>
              </ContentWrapper>
            </Card4>
          </div>
        </CardWrapper>
      </Whatwedo>
    </Wrapper>
  );
}

const CardTitle = styled(BodyIntro)`
  color: rgba(255, 255, 255);
  font-style: normal;
  font-size: 24px;
  line-height: 110%;
  font-weight: bold;
  text-align: center;
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const IllustrationWrapper = styled.div`
  position: relative;
  display: grid;
  -webkit-box-pack: center;
  justify-content: center;
  justify-items: center;
  height: 150px;
`;

const Wrapper = styled.div`
  position: relative;
  margin-top: 200px;
  overflow: visible;
`;

const HeroWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 380px auto;
  column-gap: 80px;
  max-width: 1234px;
  margin: 0px auto;
  padding: 0px 30px;
  align-items: flex-end;
  pointer-events: none;

  @media (max-width: 970px) {
    grid-template-columns: 1fr;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  max-width: 380px;
  display: grid;
  gap: 20px;

  @media (max-width: 1024px) {
    margin: auto;
    justify-items: center;
    text-align: center;
  }
`;

const Heading = styled(Caption2)`
  color: rgba(0, 0, 0, 0.7);

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Title = styled(H2)`
  color: rgba(0, 0, 0);
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255);
  }
`;

const Subtitle = styled(MediumText)`
  color: rgba(0, 0, 0);
  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255);
  }
`;

const IconRow = styled.div`
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 20px 0px;
  pointer-events: visible;

  @media (max-width: 1280px) {
    -webkit-box-pack: center;
    justify-content: center;
    justify-items: center;
  }
`;

const LogoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 20px;

  @media (max-width: 510px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  grid-template-columns: repeat(1, 1fr);
  display: none;
  gap: 20px;

  @media (max-width: 510px) {
    display: grid;
    padding-top: 20px;
    width: 250px;
  }
`;

const LogoWrapperMob1 = styled.div`
  display: none;

  @media (max-width: 510px) {
    display: flex;
    justify-content: space-space-between;
  }
`;

const LogoWrapperMob2 = styled.div`
  display: none;
  @media (max-width: 510px) {
    display: flex;
    justify-content: space-space-between;
    margin: auto;
    width: 190px;
  }
`;

const Icon = styled.img`
  width: 44px;
  height: 44px;
  margin: 0px;
  opacity: 1;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  @media (max-width: 510px) {
    margin: auto;
  }
  @media (prefers-color-scheme: dark) {
    box-shadow: none;
  }
  :hover {
    box-shadow: 0px 20px 40px rgba(0, 10, 0, 0.55);
    transition: 0.2s ease-in-out;
    transform: translateY(-2px);
  }
`;

const Whatwedo = styled.div`
  display: contents;
  grid-template-columns: repeat(auto-fit, 218px);
  justify-items: center;
  gap: 20px;
  max-width: 1234px;
  margin: 40px auto;
  padding: 40px 30px;
  position: relative;
  top: -40px;
`;

const CardWrapper = styled.div`
  * {
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 40px;
  max-width: 1254px;
  margin: 40px auto 0px;
  padding: 0px 40px;

  @media (max-width: 1276px) {
    grid-template-columns: repeat(2, minmax(240px, 1fr));
  }
  @media (max-width: 610px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
`;

const Card = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  min-width: 200px;
  height: 360px;
  border-radius: 20px;

  overflow: hidden;
  padding: 20px;

  :hover {
    transform: scale(1.1);
  }
`;

const Card1 = styled(Card)`
  background: linear-gradient(
    209.21deg,
    rgb(87, 15, 141) 13.57%,
    rgb(243, 91, 160) 98.38%
  );
  box-shadow: rgb(243 91 160 / 30%) 0px 20px 40px, rgb(0 0 0 / 5%) 0px 1px 3px;
`;

const Card2 = styled(Card)`
  background: linear-gradient(
    209.21deg,
    rgb(12, 39, 178) 13.57%,
    rgb(133, 218, 213) 98.38%
  );
  box-shadow: rgb(133 218 213 / 30%) 0px 20px 40px, rgb(0 0 0 / 5%) 0px 1px 3px;
`;

const Card3 = styled(Card)`
  background: linear-gradient(
    209.21deg,
    rgb(193, 48, 39) 13.57%,
    rgb(226, 131, 115) 98.38%
  );
  box-shadow: rgb(226 131 115 / 30%) 0px 20px 40px, rgb(0 0 0 / 5%) 0px 1px 3px;
`;

const Card4 = styled(Card)`
  background: linear-gradient(
    209.21deg,
    rgb(17, 39, 174) 13.57%,
    rgb(90, 113, 251) 98.38%
  );
  box-shadow: rgb(90 113 251 / 30%) 0px 20px 40px, rgb(0 0 0 / 5%) 0px 1px 3px;
`;

const Illustration = styled.img`
  height: 150px;
  margin: 0px;
  opacity: 1;
  animation: 1s ease 0s 1 normal forwards running;
  transform: translateY(0);
  ${Card}:hover & {
    transform: translateY(-250px);
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  padding-top: 10px;
  ${Card}:hover & {
    transform: translateY(-170px);
  }
`;

const Website = styled(Caption2)`
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  margin: 20px 0px 0px;
  opacity: 1;

  ${Card}:hover & {
    opacity: 0;
  }
`;

const CardSubtitle = styled(Caption)`
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin: 20px 0px 0px;

  ${Card}:hover & {
    opacity: 0;
  }
`;

const HiddenDetails = styled.div`
  position: absolute;
  top: 0;
  ${Card}:hover & {
    transform: translateY(60px);
  }
`;

const HiddenTitle = styled(Caption)`
  color: rgba(255, 255, 255, 0.9);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  margin: 0;
  opacity: 0;
  margin-top: 15px;
  ${Card}:hover & {
    opacity: 1;
  }
`;

const HiddenText = styled(Caption)`
  color: rgba(255, 255, 255, 0.7);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 5px;
  opacity: 0;
  ${Card}:hover & {
    opacity: 1;
  }
`;
