import React from "react";
import styled, { keyframes } from "styled-components";
import MockupAnimation from "../animations/MockupAnimation";
import WaveBackground from "../backgrounds/WaveBackground";
import CtaButton from "../buttons/CtaButton";
import { themes } from "../styles/ColorStyles";
import { H1, MediumText } from "../styles/TextStyles";
import HeroBlob from "../backgrounds/HeroBlob";

function HeroSection() {
  return (
    <Wrapper id="home">
      <WaveBackground />
      <ContentWrapper>
        <TextWrapper>
          <HeroBlob />
          <Title>
            Content, Marketing <span>&</span> Clicks.
          </Title>
          <Description>
            Eficiență, performanță și pasiune sunt cuvintele care ne
            caracterizează cel mai bine, iar tu ești în locul potrivit dacă îți
            dorești toate aceste lucruri pentru afacerea ta din mediul online.
          </Description>
          <CtaButton title="Trimite-ne un mesaj" subtitle="Evaluare gratuită" />
        </TextWrapper>
        <MockupAnimation />
      </ContentWrapper>
    </Wrapper>
  );
}

export default HeroSection;

const animation = keyframes`
from { opacity: 0; transform: translateY(-10px); filter:blur(10px);}
to { opacity: 1; transform: translateY(0px); filter:blur(0px);}
`;

const Wrapper = styled.div`
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  max-width: 1234px;
  margin: 0 auto;
  padding: 200px 30px;
  display: grid;
  grid-template-columns: 360px auto;

  @media (max-width: 450) {
    grid-template-columns: auto;
    gap: 60px;
    padding: 150px 20px 250px;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  max-width: 360px;
  display: grid;
  gap: 30px;

  > * {
    opacity: 0;
    animation: ${animation} 1s forwards;

    :nth-child(2) {
      animation-delay: 0s;
    }
    :nth-child(3) {
      animation-delay: 0.2s;
    }
    :nth-child(4) {
      animation-delay: 0.4s;
    }
  }
`;

const Title = styled(H1)`
  color: ${themes.dark.text1};
  background: linear-gradient(180deg, #730040 0%, #301cbe 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  span {
    background: linear-gradient(180deg, #ffd7ff 0%, #ffb6ff 100%);

    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  @media (max-width: 450px) {
    font-size: 58px;
  }
`;

const Description = styled(MediumText)`
  font-size: 17px;
  line-height: 130%;
`;
